<template>
    <div>AccountReviewHistoryDetail</div>
</template>

<script>
    export default {
        name: 'AccountReviewHistoryDetail',

        created() {
            this.$store.commit('setBreadcrumbItems', [
                { text: 'Home', routeName: 'Home' },
                { text: 'Account', routeName: 'AccountProfile' },
                { text: 'Reviews', routeName: 'AccountReviewIndex' },
                { text: 'My Review', routeName: 'AccountReviewHistoryIndex' },
                { text: 'Check All Review', routeName: 'AccountReviewHistoryDetail' }
            ]);
        }
    };
</script>
